import { Suspense } from "react";
import { Stack } from "@mui/material";

import { entities } from "../../../component/Search/Hooks";
import FilterChips from "../../../component/Search/Suggestions/Filter/Chips";
import FilterTime from "./Chip/Time";
import FilterSort from "./Chip/Sort";
import FilterTask from "./Chip/Task";
import FilterPublisher from "./Chip/Publishers";
import FilterStars from "./Chip/Stars";

const Filters = () => (
  <Stack
    spacing={2}
    direction="row"
    justifyContent="space-between"
    mx="-16px !important"
    px="16px !important"
    pb={0.25}
    sx={filterChipScrollSx}
  >
    <Stack useFlexGap direction="row" spacing={1}>
      {entities.map(({ Icon, label, href }) => (
        <Suspense key={label}>
          <FilterChips label={label} Icon={Icon} href={href} />
        </Suspense>
      ))}
    </Stack>
    <Stack useFlexGap direction="row" alignItems="center" spacing={1}>
      {[FilterStars, FilterPublisher, FilterTask, FilterTime, FilterSort].map(
        (Filter, index) => (
          <Suspense key={index}>
            <Filter />
          </Suspense>
        )
      )}
    </Stack>
  </Stack>
);

export default Filters;

export const filterChipScrollSx = {
  zIndex: 1,
  overflow: "auto",
  pr: { compact: 2, expanded: 0 },
  // disable scroll bar
  "&::-webkit-scrollbar": {
    display: "none"
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */
};
