import {
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemButton
} from "@mui/material";
import {
  Search as SearchIcon,
  History as RecentIcon
} from "@mui/icons-material";

import { observer, useStore } from "../../../service/mobx";
import { entityMap } from "../Hooks";
import { useDialog } from "../../Card/External/Video";

export default observer(function SearchList({
  entity,
  searchSuggestion = true
}) {
  const { analytics, search } = useStore();
  const map = {
    recent: {
      leading: { Icon: RecentIcon },
      line1: entity.recentQuery,
      href: `/search?q=${entity.recentQuery}`
    },
    paper: {
      leading: { Icon: entityMap.paper.Icon },
      // leading: { Icon: SearchIcon },
      line1: entity.title,
      // trailing: { Icon },
      // line2: <SubheaderPaper publisher org authors paper={entity} />,
      href: `/docs/${entity.publisher}/${entity.paperID}/paper`
    },
    tag: {
      leading: { Icon: entityMap.task.Icon },
      // leading: { img: `https://cdn.bytez.com/images/v2/${entity.name}.png` },
      line1: entity.name,
      // line2: <SubheaderTask task={entity.name} />,
      // href: `/task/${tasks.url.encode(entity.name)}`
      href: `/search?type=papers&task=${entity.name}`

      // trailing: {
      //   Icon: ({ sx }) => <TaskFilter sx={sx} tag={entity} />
      // }
    },
    model: {
      leading: { Icon: entityMap.model.Icon },
      line1: entity.title,
      line2: entity.publisher,
      href: `/docs/model/${entity.modelId}`
    },
    video: {
      leading: { Icon: entityMap.video.Icon },
      // leading: { videoThumbnail: entity.img },
      line1: entity.title
      // line2: <SubheaderVideo video={entity} />
    }
  };
  const type = entity.__typename.toLowerCase();
  const playVideo = useDialog(type === "video" ? entity : undefined);

  return searchSuggestion ? (
    <Pattern
      onMouseEnter={() => search.set.suggestedEntityInFocus(entity)}
      {...map[type]}
      onClick={() => {
        analytics.track.event("Search Suggestion", { type, id: entity.id });

        if (type === "video") {
          playVideo();
        }
      }}
    />
  ) : (
    <Pattern {...map[type]} />
  );
});

export const Pattern = ({
  href,
  line1,
  line2,
  line3,
  leading = {},
  trailing = {},
  onMouseEnter,
  onClick
}) => (
  <ListItemButton
    href={href}
    onMouseEnter={onMouseEnter}
    onClick={onClick}
    sx={{
      p: 1,
      height: line3 === undefined ? (line2 === undefined ? 56 : 72) : 88,
      maxHeight: 88
    }}
  >
    {leading.Avatar ? (
      <ListItemAvatar>
        <Avatar
          sx={{
            height: 40,
            width: 40,
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <leading.Avatar />
        </Avatar>
      </ListItemAvatar>
    ) : leading.img ? (
      <ListItemAvatar sx={{ pr: 2, pl: 1 }}>
        <Avatar
          variant="rounded"
          src={leading.img}
          sx={{
            height: 56,
            width: "auto",
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <SearchIcon />
        </Avatar>
      </ListItemAvatar>
    ) : leading.videoThumbnail ? (
      <ListItemAvatar sx={{ pr: 2 }}>
        <Avatar
          variant="rounded"
          src={leading.videoThumbnail}
          sx={{
            height: 64,
            width: "auto",
            aspectRatio: "16 / 9",
            color: "primary.on.container",
            bgcolor: "primary.container",
            typography: "titleMd"
          }}
        >
          <SearchIcon />
        </Avatar>
      </ListItemAvatar>
    ) : leading.Icon ? (
      <ListItemIcon sx={{ minWidth: "unset", pr: 2, pl: 1 }}>
        <leading.Icon
          sx={{
            width: 24,
            height: 24,
            color: "surface.on.variant"
          }}
        />
      </ListItemIcon>
    ) : null}
    <ListItemText
      primary={line1}
      primaryTypographyProps={{
        typography: { compact: "bodyMd", expanded: "bodyLg" },
        color: "surface.on.color",
        textTransform: "capitalize",
        sx: theme => theme.typography.lineClamp(1)
      }}
      secondary={line2}
      secondaryTypographyProps={{
        variant: "bodyMd",
        color: "surface.on.variant",
        sx: theme => theme.typography.lineClamp(1)
      }}
    />
    {trailing.Icon ? (
      <ListItemSecondaryAction>
        <trailing.Icon
          sx={{ color: "surface.on.variant", height: 24, width: 24 }}
        />
      </ListItemSecondaryAction>
    ) : trailing.supportText ? (
      <ListItemSecondaryAction>
        <ListItemText
          primary={trailing.supportText}
          primaryTypographyProps={{
            variant: "labelSm",
            color: "surface.on.variant"
          }}
        />
      </ListItemSecondaryAction>
    ) : null}
  </ListItemButton>
);
