import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/EmojiEventsOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/TrendingUp.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/component/AppBar/bars/Discover.js");
;
import(/* webpackMode: "eager", webpackExports: ["PaperCardSize3"] */ "/workspace/src/component/Card/Paper.js");
;
import(/* webpackMode: "eager", webpackExports: ["CardProductSize2"] */ "/workspace/src/component/Card/Product.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Content/Customize/Tasks/Following.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Content/Customize/Tasks/Trending/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Content/Following/index.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/view/discover/Content/Trending/Masonry.js");
