import { useEffect } from "react";
import { Box } from "@mui/material";

import { observer, useStore } from "../../../service/mobx";
import { PaperCardSize5 as PaperCard } from "../../Card/Paper";
import { CardTaskSize3 as TaskCard } from "../../Card/Task";
import { CardVideoSize2 as VideoCard } from "../../Card/External/Video";

function SuggestionPane() {
  const { device, search } = useStore();
  const cards = { paper: PaperCard, tag: TaskCard, video: VideoCard };
  const entityName = search.suggestedEntityInFocus?.__typename?.toLowerCase();
  const Card = cards[entityName] ?? (() => {});
  const props = { [entityName]: search.suggestedEntityInFocus };

  useEffect(() => search.set.suggestedEntityInFocus, [search]);

  return search.suggestedEntityInFocus && device.isPhone === false ? (
    <Box p={2} display="flex" justifyContent="center">
      <Card tone="surface.container.lowest" {...props} />
    </Box>
  ) : null;
}

export default observer(SuggestionPane);
