import { Unstable_Grid2 as Grid } from "@mui/material";

const _50_50 = ({ children, Pane = () => null, sx }) => (
  <Grid container columns={10} sx={sx} pr={1}>
    <Grid compact={10} large={5}>
      {children}
    </Grid>
    <Grid compact="auto" large={5}>
      <Pane />
    </Grid>
  </Grid>
);

export default _50_50;
