import { Stack } from "@mui/material";

import { TaskSize1 } from "../../../../../component/Card/Task";
import LabelWithIcon from "../../../../../component/LabelWithIcon";

const TaskLayout = ({ label, Icon = () => null, list = [], button }) =>
  list.length === 0 ? null : (
    <div>
      <LabelWithIcon Icon={Icon} label={label} size={20} />
      <Stack
        useFlexGap
        spacing={1}
        flexWrap="wrap"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack useFlexGap spacing={1} direction="row" flexWrap="wrap">
          {list.map(entity => (
            <TaskSize1 key={entity.id} tag={entity} />
          ))}
        </Stack>
        {button}
      </Stack>
    </div>
  );

export default TaskLayout;
