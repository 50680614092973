"use client";
import { Fragment, useState } from "react";
import {
  Avatar,
  Box,
  Unstable_Grid2 as Grid,
  CardHeader,
  Stack,
  Typography,
  IconButton
} from "@mui/material";
import {
  OpenInFull as ExpandIcon,
  Close as CloseIcon,
  PlayArrow as PlayArrowIcon
} from "@mui/icons-material";
import ReactPlayer from "react-player/youtube";

import { observer, useStore } from "../../../service/mobx";
import FilledCard from "../Filled";
import GameBadge from "../../GameBadge";
import Star from "../../Star";

export const CardVideoSize1 = ({ video, tone }) => (
  <Card video={video} tone={tone} sx={{ p: 0 }}>
    <VideoThumbail video={video} maxHeight={120} borderRadius="top" />
    <Box p={1} pt={0.5}>
      <Header size="small" video={video} pb={0} />
    </Box>
  </Card>
);
export const CardVideoSize2 = ({ video, tone }) => (
  <Card
    video={video}
    tone={tone}
    sx={{
      p: 2,
      display: "flex",
      width: "100%",
      justifyContent: "flex-start"
    }}
  >
    <Grid container columns={10} spacing={2}>
      <Grid compact={10}>
        <VideoThumbail video={video} width="100%" />
      </Grid>
      <Grid compact={10}>
        <Header size="medium" video={video} />
        <Description text={video.description} />
      </Grid>
    </Grid>
  </Card>
);
export const CardVideoSize3 = ({ video, tone }) => (
  <Card
    video={video}
    tone={tone}
    sx={{
      display: "flex",
      justifyContent: "flex-start",
      maxHeight: 480,
      width: "calc(360px + 55ch)",
      maxWidth: "100%",
      boxSizing: "border-box"
    }}
  >
    <Grid container columns={10} width="100%">
      <Grid compact={10} medium="auto">
        <VideoThumbail video={video} width={360} />
      </Grid>
      <Grid
        compact={10}
        expanded
        pl={{ compact: 0, expanded: 2 }}
        pt={{ compact: 1, expanded: 0 }}
        pb={{ compact: 4, expanded: 0 }}
      >
        <Header video={video} />
        <Description text={video.description} />
      </Grid>
    </Grid>
  </Card>
);
const PlayIcon = () => (
  <IconButton
    size="large"
    sx={theme => ({
      bgcolor: "dark.surface.color",
      opacity: 0.86,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: theme.transitions.create("all"),
      "&:hover": {
        opacity: 1,
        bgcolor: "dark.surface.color"
      }
    })}
  >
    <PlayArrowIcon sx={{ color: "dark.primary.color" }} />
  </IconButton>
);
function VideoThumbail({
  video,
  width = "100%",
  maxHeight,
  borderRadius = "round"
}) {
  const [noImage, setNoImage] = useState(false);

  return (
    <Box maxWidth="100%" boxSizing="border-box" position="relative">
      {noImage ? null : (
        <Box
          component="img"
          alt={video.title}
          width={width}
          maxWidth="100%"
          maxHeight={maxHeight}
          onError={() => setNoImage(true)}
          src={video.img || video.thumbnails?.standard?.url || video.thumbnail}
          sx={theme => ({
            objectFit: "cover",
            borderRadius: theme.shape.md[borderRadius]
          })}
        />
      )}
      <PlayIcon />
    </Box>
  );
}
export function Subheader({ video }) {
  const { utilities } = useStore();
  const items = [
    video.viewCount || video.views
      ? `${utilities.formatNumber(video.viewCount || video.views)} views`
      : undefined,
    video.duration || video.durationSeconds
      ? `${Math.round((video.duration || video.durationSeconds) / 60)} mins`
      : undefined,
    video.published ? utilities.paperTime(video.published) : undefined,
    video.channel?.title
  ].filter(defined => defined);

  return items.length ? (
    <Stack direction="row" alignItems="center" flexWrap="wrap" width="100%">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          {content}
          {index + 1 === array.length ? undefined : (
            <Box component="span" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  ) : null;
}
const Header = ({
  video,
  size = "large",
  color = "surface.on.color",
  pb = 3
}) => (
  <CardHeader
    avatar={
      video.scoreTrending ? (
        <GameBadge scoreTrending={video.scoreTrending} />
      ) : video.channel?.img ? (
        <Avatar src={video.channel.img} />
      ) : undefined
    }
    title={video.title}
    titleTypographyProps={{
      color,
      variant: `title${size === "large" ? "Lg" : "Md"}`,
      typography: {
        compact: "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : `title${size === "large" ? "Lg" : "Md"}`
      },
      sx: theme => theme.typography.lineClamp(2)
    }}
    subheader={<Subheader video={video} />}
    subheaderTypographyProps={{
      color,
      display: "flex",
      alignItems: "center",
      typography: { compact: "labelSm", medium: "labelMd", expanded: "labelLg" }
    }}
    action={
      <Box px={1}>
        <Star size={size} entity={video} />
      </Box>
    }
    sx={{ p: 0, pb, alignItems: "flex-start", width: "100%" }}
  />
);
function Card({ video, tone = "surface.container.color", children, sx = {} }) {
  const { analytics } = useStore();
  const playVideo = useDialog(video);

  return (
    <FilledCard
      tone={tone}
      sx={{ p: 0, ...sx, cursor: "pointer" }}
      onClick={() => {
        analytics.track.event("Video Click", {
          publisher: video.publisher,
          videoID: video.videoID
        });
        playVideo();
      }}
    >
      {children}
    </FilledCard>
  );
}
const Description = ({ text }) =>
  text ? (
    <Typography
      maxWidth="75ch"
      variant="bodyMd"
      color="surface.on.color"
      sx={theme => theme.typography.lineClamp(4)}
    >
      {text}
    </Typography>
  ) : null;

const DialogPlayer = observer(function DialogPlayer({ video }) {
  const { analytics, device, dialog, videos } = useStore();
  const [ref, setRef] = useState(null);
  const [, setRemount] = useState();
  const { width = 0 } = ref?.getBoundingClientRect() ?? {};
  const height = device.isDesktop ? (width / 16) * 9 : "calc(100% - 64px)";
  const isFullScreen = document.fullscreenElement === null;

  return (
    <Box p={2} ref={setRef}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        pb={device.isDesktop || !isFullScreen ? 0 : 2}
      >
        {device.isDesktop ? null : (
          <IconButton
            aria-label="Play"
            size="small"
            onClick={() => dialog.set.open(false)}
          >
            <CloseIcon sx={{ color: "dark.surface.on.color" }} />
          </IconButton>
        )}
        {device.isDesktop || !isFullScreen ? null : (
          <IconButton
            aria-label="Play"
            size="small"
            onClick={() =>
              ref.requestFullscreen() && setTimeout(setRemount, 100, 1)
            }
          >
            <ExpandIcon sx={{ color: "dark.surface.on.color" }} />
          </IconButton>
        )}
        {!device.isDesktop && isFullScreen ? null : (
          <Header video={video} size="large" color="dark.surface.on.color" />
        )}
      </Stack>
      {!device.isDesktop && isFullScreen ? (
        <Header video={video} size="large" color="dark.surface.on.color" />
      ) : null}
      {video.url.includes("slideslive.com") ? (
        <Box
          src={video.clean.embed}
          component="iframe"
          width="100%"
          height="100vh"
          allow="autoplay; fullscreen"
          frameborder="0"
        />
      ) : (
        <ReactPlayer
          stopOnUnmount
          width={width}
          height={height}
          playIcon={<PlayIcon />}
          playing={videos.playing === video.id}
          onEnded={() => {
            videos.set.playing();
            analytics.track.event("Video Finish", {
              publisher: video.publisher,
              videoID: video.videoID
            });
          }}
          onPlay={() => {
            videos.set.playing(video.id);
            analytics.track.event("Video Play", {
              publisher: video.publisher,
              videoID: video.videoID
            });
          }}
          wrapper={({ ...props }) => (
            <Box
              sx={{
                maxWidth: "100%",
                boxSizing: "border-box",
                "& iframe": {
                  borderRadius: theme => theme.shape.xl.round
                }
              }}
              {...props}
            />
          )}
          url={video.url}
        />
      )}
    </Box>
  );
});

export function useDialog(video) {
  const { dialog, videos } = useStore();

  return () => {
    setTimeout(videos.set.playing, 2e3, video.id);

    dialog.configure({
      Content: () => <DialogPlayer video={video} />,
      sx: {
        paper: {
          overflow: "hidden",
          boxShadow: "unset",
          maxWidth: { compact: "100vw", large: "1200px" },
          maxHeight: { compact: "100vh", large: "800px" },
          height: { compact: "100vh" },
          width: { compact: "100vw" },
          bgcolor: "transparent"
        },
        scrim: {
          bgcolor: theme =>
            theme.palette.alpha(
              theme.palette.dark.surface.container.lowest,
              0.9
            ),
          transition: theme =>
            `${theme.transitions.create("all", {
              duration: 2e3,
              easing: theme.motion.easing.emphasized
            })} !important`
        }
      }
    });
  };
}
