import { useEffect, useState } from "react";
import { List } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";
import SearchList from "./List";

export default observer(function RecentSearches() {
  const [recentSearches, setRecent] = useState([]);
  const { analytics, search, utilities } = useStore();
  const q = search.searchParams.get("q") || "";

  useEffect(() => {
    utilities.cache
      .get("recent-searches")
      .then(recentSearches => setRecent(recentSearches || []));
  }, [utilities]);

  useEffect(() => {
    if (q) {
      utilities.cache.get("recent-searches").then(recentSearches => {
        const recentSearchSet = new Set(recentSearches || []);

        recentSearchSet.add(q);
        utilities.cache.set(
          "recent-searches",
          [...recentSearchSet].reverse().slice(0, 5)
        );
      });
    }
  }, [utilities, q]);

  return q === "" &&
    recentSearches.length !== 0 &&
    search.suggestions.length === 0 ? (
    <List
      dense
      onClick={() =>
        analytics.track.event("Search Suggestion", { type: "history" })
      }
    >
      {recentSearches.map(recentQuery => (
        <SearchList
          key={recentQuery}
          entityName="recent"
          entity={{ recentQuery, __typename: "recent" }}
        />
      ))}
    </List>
  ) : null;
});
