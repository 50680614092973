import { SearchBar } from "../../../Search";
import Suggestions from "../../../Search/Suggestions";

export const dialogSearch = {
  Content: () => (
    <div>
      <SearchBar fullScreen sx={{ mx: -2, bgcolor: "unset" }} />
      <Suggestions fullScreen sx={{ ml: -3, bgcolor: "unset" }} />
    </div>
  )
};
