"use client";
import { useState, useRef } from "react";
import { usePathname, useRouter } from "next/navigation";
import {
  Box,
  Grow,
  IconButton,
  InputBase,
  Stack,
  LinearProgress,
  Fade
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  ArrowBack as BackIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";
import Suggestions from "./Suggestions";
import { dialogSearch } from "../Dialog/dialogs/search";

function Search() {
  const { device, search } = useStore();
  const sx =
    search.showSuggestions && device.isPhone === false
      ? { boxShadow: theme => theme.elevation[2] }
      : undefined;

  return (
    <Box position="relative">
      <SearchBar sx={sx} />
      {device.isPhone ? null : <Suggestions sx={sx} />}
    </Box>
  );
}
export default observer(Search);
export const SearchBar = observer(function SearchBar({
  sx,
  fullScreen = false
}) {
  const [clickedBack, setClicked] = useState(false);
  const { analytics, device, dialog, search } = useStore();
  const router = useRouter();
  const inputRef = useRef();
  const LeadIcon =
    usePathname() === "/search" || fullScreen ? BackIcon : SearchIcon;
  const { userInput } = search;
  const roundCorners = search.showSuggestions && device.isPhone === false;

  return (
    <Stack
      px={fullScreen ? 0 : 2}
      pl={fullScreen ? 0 : 1}
      height={56}
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="surface.container.high"
      borderRadius={theme =>
        roundCorners ? theme.shape.xl.top : theme.shape.round
      }
      sx={
        search.showSuggestions
          ? sx
          : theme => ({
              ...sx,
              transition: theme.transitions.create("all"),
              ...theme.motion.transition.exit.temp,
              transitionDelay:
                theme.motion.transition.exit.temp.transitionDuration
            })
      }
    >
      <IconButton
        size="small"
        disabled={clickedBack || LeadIcon === SearchIcon}
        onClick={() => {
          if (dialog.open) {
            search.set.isFocused(false);
            dialog.set.open(false);
          } else {
            router.push("/");
          }

          setClicked(true);
        }}
      >
        <LeadIcon sx={{ color: "surface.on.color" }} />
      </IconButton>
      <InputBase
        fullWidth
        autoFocus={fullScreen}
        value={userInput}
        placeholder="Search for papers, videos, tasks..."
        sx={{ color: "surface.on.variant", typography: "bodyLg" }}
        onFocus={() => search.set.isFocused(true)}
        inputProps={{
          autoFocus: fullScreen,
          ref: inputRef,
          onBlur: fullScreen ? undefined : () => search.set.isFocused(null),
          onFocus() {
            if (device.isPhone && clickedBack === false) {
              // if this mobile, then allow the 1st component/non-fullscreen to open the dialog
              if ((fullScreen && dialog.open) === false) {
                // blur the non-dialog search bar
                inputRef.current.blur();
                // mount the 2nd search bar, that sits in a dialog
                dialog.configure(dialogSearch);
              }
            }
          },
          sx: { color: "surface.on.color", typography: "bodyLg" }
        }}
        onChange={event => search.set.userInput(event.target.value)}
        onKeyUp={event => {
          if (event.key === "Enter" || event.key === "Escape") {
            const q = userInput.trim();

            inputRef.current.blur();
            search.set.isFocused(false);

            if (dialog.open) {
              dialog.set.open(false);
            }

            if (event.key === "Enter") {
              if (q) {
                search.updateUrlParams(router, { q: [q] });
                analytics.track.event("Search Query", { q });
              } else {
                search.set.userInput();
              }
            }
          }
        }}
      />
      <Fade
        mountOnEnter
        unmountOnExit
        in={
          search.showSuggestions === false &&
          (search.loadingSuggestions || search.loadingResults) &&
          device.isPhone === false
        }
      >
        <Box
          pr={6}
          bottom={0}
          width="100%"
          overflow="hidden"
          position="absolute"
        >
          <LinearProgress sx={{ height: 2 }} />
        </Box>
      </Fade>
      <Grow
        mountOnEnter
        unmountOnExit
        in={search.isFocused === true && search.userInput !== ""}
      >
        <IconButton
          size="small"
          onClick={() => {
            search.updateUrlParams(router, { q: undefined });
            search.set.userInput();
            search.set.isFocused(device.isPhone);
          }}
        >
          <ClearIcon sx={{ color: "surface.on.variant" }} />
        </IconButton>
      </Grow>
    </Stack>
  );
});
