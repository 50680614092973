import { Stack, Typography } from "@mui/material";

import { useStore, observer } from "../../../../service/mobx";
import { entities } from "../../Hooks";
import SearchFilterChip from "./Chips";
import { filterChipScrollSx } from "../../../../view/search/Filters";

function SuggestionsFilter() {
  const { dialog, search } = useStore();

  return (
    <>
      <Typography
        gutterBottom
        pl={2}
        pt={2}
        component="p"
        variant="titleSm"
        color="surface.on.color"
        textTransform="capitalize"
      >
        {search.userInput ? "Filter" : "Trending"}
      </Typography>
      <Stack
        useFlexGap
        flexWrap="wrap"
        direction="row"
        spacing={1}
        mx={-2}
        px="32px !important"
        sx={{
          ...filterChipScrollSx,
          overflow: { compact: "auto", expanded: "visible" }
        }}
        pb={2}
        onClick={() => {
          if (dialog.open) {
            dialog.reset();
          }
        }}
      >
        {entities.map(({ entityName, Icon, label, href }) => (
          <SearchFilterChip
            key={entityName}
            Icon={Icon}
            label={label}
            href={href}
          />
        ))}
      </Stack>
    </>
  );
}

export default observer(SuggestionsFilter);
