"use client";
import dynamic from "next/dynamic";
import { IconButton, Stack, Typography } from "@mui/material";

import { observer, useStore } from "../../../service/mobx";
import UserAvatar from "../../Avatar/User";
import { dialogSearch } from "../../Dialog/dialogs/search";

const MarketingPageIcon = dynamic(() =>
  import("@mui/icons-material/InfoOutlined")
);
const SearchIcon = dynamic(() => import("@mui/icons-material/Search"));

function MobileBar() {
  const { device, dialog } = useStore();

  return device.isPhone ? (
    <Stack height={72}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="brand"
          fontWeight="bold"
          color="surface.on.color"
          width="100%"
          pl={1}
          fontSize={{ compact: 24, expanded: 16 }}
        >
          bytez
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={() => dialog.configure(dialogSearch)}>
            <SearchIcon sx={{ color: "surface.on.variant" }} />
          </IconButton>
          <IconButton href="/about">
            <MarketingPageIcon
              sx={{ color: "secondary.color", height: 20, width: 20 }}
            />
          </IconButton>
          <UserAvatar />
        </Stack>
      </Stack>
    </Stack>
  ) : null;
}

export default observer(MobileBar);
