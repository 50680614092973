"use client";
import { Box, List, LinearProgress, Collapse, Typography } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";
import Filter from "./Filter";
import Layout from "../../../layout/5-5";
import SuggestionPane from "./SuggestionPane";
import SearchList from "./List";
import RecentSearches from "./Recent";

function Suggestions({ sx }) {
  const { device, search } = useStore();

  return (
    <Box
      sx={{
        width: "100%",
        zIndex: 10,
        position: "absolute",
        bgcolor: "surface.container.high",
        borderRadius: theme => theme.shape.xl.bottom,
        ...sx
      }}
    >
      <Collapse
        in={search.showSuggestions}
        sx={theme => ({
          ...theme.motion.transition[search.showSuggestions ? "enter" : "exit"]
            .temp
        })}
      >
        <Box borderTop={1} borderColor="outline.color">
          <Collapse in={search.loadingSuggestions}>
            <LinearProgress />
          </Collapse>
          <Layout Pane={device.isDesktop ? SuggestionPane : undefined}>
            <Filter />
            <RecentSearches />
            <Collapse in={search.loadingSuggestions === false}>
              {search.suggestions.length ? (
                <List dense>
                  <Typography
                    pl={2}
                    component="p"
                    variant="titleSm"
                    color="surface.on.color"
                    textTransform="capitalize"
                  >
                    Suggestions
                  </Typography>
                  {search.suggestions.map(entity => (
                    <SearchList
                      key={entity.id}
                      entity={entity}
                      entityName={entity.__typename}
                    />
                  ))}
                </List>
              ) : (
                <div />
              )}
            </Collapse>
          </Layout>
        </Box>
      </Collapse>
    </Box>
  );
}

export default observer(Suggestions);
